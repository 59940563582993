import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Value,
} from '@/components';
import { EditIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerBirthDateForm,
  PlayerCityForm,
  PlayerNameForm,
  PlayerStreetForm,
  PlayerZipCodeForm,
} from '@/forms';
import { Nullable } from '@/types';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import {
  PlayerKycOverviewBox,
  PlayerKycOverviewBoxVariables,
} from './__generated__/PlayerKycOverviewBox';
import { SanityPlayerKycOverviewBlockFragment } from './__generated__/SanityPlayerKycOverviewBlockFragment';
import { KycStatusValue } from './kyc-status-value';
import { PlayersLinkedBySourceOfWealthValue } from './players-linked-by-source-of-wealth-value';

export const Fragment = graphql`
  fragment SanityPlayerKycOverviewBlockFragment on SanityPlayerKycOverviewBlock {
    title {
      ...LocaleString
    }
    firstNameLabel {
      ...LocaleString
    }
    lastNameLabel {
      ...LocaleString
    }
    titleLabel {
      ...LocaleString
    }
    ssnLabel {
      ...LocaleString
    }
    birthDateLabel {
      ...LocaleString
    }
    countryLabel {
      ...LocaleString
    }
    zipCodeLabel {
      ...LocaleString
    }
    streetLabel {
      ...LocaleString
    }
    cityLabel {
      ...LocaleString
    }
    genderLabel {
      ...LocaleString
    }
    upToDateVerificationsLabel {
      ...LocaleString
    }
    kycStatusLabel {
      ...LocaleString
    }
    kycStatusWillBeRequired {
      ...LocaleString
    }
    kycStatusRequired {
      ...LocaleString
    }
    kycStatusPending {
      ...LocaleString
    }
    kycStatusFailed {
      ...LocaleString
    }
    kycStatusNotNeeded {
      ...LocaleString
    }
    kycStatusRejected {
      ...LocaleString
    }
    kycStatusDone {
      ...LocaleString
    }
    outdatedVerificationsLabel {
      ...LocaleString
    }
    sharedSowAccountsLabel {
      ...LocaleString
    }
    expiredVerificationsLabel {
      ...LocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerKycOverviewBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      firstName
      lastName
      title
      ssn
      birthDate
      countryCode
      zipCode
      street
      city
      gender
      verificationTypes {
        upToDateVerifications {
          verificationType
        }
        expiredVerifications
      }
      kycCheckStatus {
        status
        kycFlow {
          status
        }
        sumSubEndUserId
      }
      playersLinkedBySourceOfWealth {
        id
        player {
          id
          uuid
          brand {
            name
          }
        }
      }
    }
  }
`;

const getUpToDateVerificationsString = (
  data: Nullable<PlayerKycOverviewBox>,
) => {
  return data?.player.verificationTypes?.upToDateVerifications
    .map((a) => a.verificationType)
    .join(', ');
};

const PlayerKycOverviewBlock: FC<{
  block: SanityPlayerKycOverviewBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerKycOverviewBox,
    PlayerKycOverviewBoxVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={t(block.firstNameLabel)}
            value={data?.player.firstName}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerNameForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.lastNameLabel)}
            value={data?.player.lastName}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerNameForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.birthDateLabel)}
            value={data?.player.birthDate}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerBirthDateForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.genderLabel)}
            value={data?.player.gender}
          />

          <Value
            fetching={fetching}
            title={t(block.ssnLabel)}
            value={data?.player.ssn}
          />
          <Value
            fetching={fetching}
            title={t(block.countryLabel)}
            value={formatCountryCode(data?.player.countryCode)}
          />
          <Value
            fetching={fetching}
            title={t(block.streetLabel)}
            value={data?.player.street}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerStreetForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.zipCodeLabel)}
            value={data?.player.zipCode}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerZipCodeForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.cityLabel)}
            value={data?.player.city}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerCityForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.upToDateVerificationsLabel)}
            value={getUpToDateVerificationsString(data)}
          />
          <KycStatusValue fetching={fetching} data={data} block={block} />
          <Value
            fetching={fetching}
            title={t(block.outdatedVerificationsLabel)}
            value={data?.player.verificationTypes?.expiredVerifications.join(
              ', ',
            )}
          />
          <PlayersLinkedBySourceOfWealthValue
            fetching={fetching}
            block={block}
            data={data}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerKycOverviewBlock;
