import React, { useCallback, useMemo } from 'react';

import { InlineIconButton, Value } from '@/components';
import { ExternalLinkIcon } from '@/components/icons';
import { TranslateFn, useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import {
  PlayerKycOverviewBox,
  PlayerKycOverviewBox_player_kycCheckStatus,
} from './__generated__/PlayerKycOverviewBox';
import { SanityPlayerKycOverviewBlockFragment } from './__generated__/SanityPlayerKycOverviewBlockFragment';

const createStatusTranslationMap = (
  t: TranslateFn,
  block: SanityPlayerKycOverviewBlockFragment,
): Record<string, string> => ({
  Done: t(block.kycStatusDone),
  Failed: t(block.kycStatusFailed),
  NotNeed: t(block.kycStatusNotNeeded),
  Pending: t(block.kycStatusPending),
  Rejected: t(block.kycStatusRejected),
  Required: t(block.kycStatusRequired),
  WillBeRequired: t(block.kycStatusWillBeRequired),
});

const hasOngoingFlowStatus = (
  flowStatus: Nullable<string>,
): flowStatus is string => {
  return !!flowStatus && ['Failed', 'Rejected', 'Pending'].includes(flowStatus);
};

const useGetKycStatus = (block: SanityPlayerKycOverviewBlockFragment) => {
  const { t } = useTranslate();

  const statusTranslationMap = useMemo(
    () => createStatusTranslationMap(t, block),
    [block, t],
  );

  const getKycStatus = useCallback<
    (
      kycCheckStatus: PlayerKycOverviewBox_player_kycCheckStatus,
    ) => string | undefined
  >(
    (kycCheckStatus) => {
      const flowStatus = kycCheckStatus.kycFlow?.status;
      const kycStatus = kycCheckStatus.status;

      const status =
        kycStatus !== 'Done' && hasOngoingFlowStatus(flowStatus)
          ? flowStatus
          : kycStatus;

      const translation = statusTranslationMap[status];

      if (translation) {
        return translation;
      }

      console.warn(`No translation for kyc status ${status}`);
      return undefined;
    },
    [statusTranslationMap],
  );

  return getKycStatus;
};

export const KycStatusValue = ({
  fetching,
  block,
  data,
}: {
  fetching: boolean;
  block: SanityPlayerKycOverviewBlockFragment;
  data: Nullable<PlayerKycOverviewBox>;
}) => {
  const { t } = useTranslate();
  const getKycStatus = useGetKycStatus(block);

  return (
    <Value
      fetching={fetching}
      title={t(block.kycStatusLabel)}
      value={
        data?.player.kycCheckStatus && getKycStatus(data.player.kycCheckStatus)
      }
      suffix={
        data?.player.kycCheckStatus?.sumSubEndUserId && (
          <InlineIconButton>
            <a
              href={`${process.env.SUMSUB_APPLICANT_PROFILE}externalUserId=${data.player.kycCheckStatus.sumSubEndUserId}`}
              target="_blank"
              rel="noreferrer"
            >
              <ExternalLinkIcon />
            </a>
          </InlineIconButton>
        )
      }
    />
  );
};
